import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import "./index.scss";
import images from "../../../assets/images/a_cars/index";
import carImages from "../../../assets/images/car/index";
import navbarImages from "../../../assets/images/navbar/index";
import { Helmet } from "react-helmet";
import { IconButton, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useWindowSize } from "../../../hooks/windowSize";
import { useFormik } from "formik";
import {
  IndexClientSearchForm,
  IndexClientSearchSchema,
  mockIndexClientSearch,
} from "./form";
import { useDebounce } from "../../../hooks/debounce";
import { getClientsByHost } from "../../../services/client";
import { ClientTable } from "../../../lib/components/clientTools/clientTable/ClientTable";
import { AddClient } from "../../../components/modals/addClient/AddClient";
import Loading from "../../../components/loading/component";

export const ClientIndex = () => {
  const { t } = useTranslation(["client", "routes"]);
  const [openAddClient, setOpenAddClient] = useState<boolean>(false);
  const [indexSearch, setIndexSearch] = useState<IndexClientSearchForm>(
    mockIndexClientSearch
  );
  const { data: clients, isLoading } = useQuery(
    ["clients", indexSearch],
    () => getClientsByHost(indexSearch),
    { enabled: !!indexSearch }
  );
  const windowSize = useWindowSize();
  const fetchEditIndexClientSearch = (values: IndexClientSearchForm) => {
    setIndexSearch(values);
  };

  const formikIndexClientSearch = useFormik<IndexClientSearchForm>({
    initialValues: {
      client: "",
    },
    validationSchema: IndexClientSearchSchema,
    onSubmit: fetchEditIndexClientSearch,
  });

  const debouncedValue = useDebounce(formikIndexClientSearch.values, 1500);

  useEffect(() => {
    if (debouncedValue) {
      formikIndexClientSearch.handleSubmit();
    }
  }, [debouncedValue]);

  return (
    <>
      <Helmet>
        <title>{t("seo.index.title")}</title>
        <meta name="description" content={t("seo.index.description")} />
        <meta name="robots" content="noindex,follow" />
        <link rel="alternate" hrefLang="es" href="https://romio.mx/mis-autos" />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://romio.mx/en/mis-autos"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://romio.mx/mis-autos"
        />
      </Helmet>
      <article className="client-index-container">
        <div className="client-index__join-container">
          <div className="client-index__title-container">
            <div className="client-index__route">
              <p className="client-index__route__first">{t("index.manages")}</p>
              <img
                src={navbarImages.arrowRight}
                className="client-index__route__arrow"
              />
              <p className="client-index__route__second">
                {t("index.clients")}
              </p>
            </div>
            <h1 data-testid="my-cars-title" className="client-index__title">
              {t("index.client")}
            </h1>
            <p className="client-index__subtitle">{t("index.manage_client")}</p>
          </div>
          <div className="client-index__search__container">
            <div className="client-index__search">
              <IconButton type="button" aria-label="search">
                <SearchIcon className="client-index__search__icon" />
              </IconButton>
              <InputBase
                className="client-index__search__input"
                placeholder={t("index.search_placeholder")}
                onChange={(e) =>
                  formikIndexClientSearch.setFieldValue(
                    "client",
                    e.currentTarget.value
                  )
                }
              />
            </div>
            {windowSize < 991 ? (
              <>
                <button
                  onClick={() => setOpenAddClient(true)}
                  className="client-index__search__add"
                >
                  <AddIcon />
                </button>
              </>
            ) : (
              <button
                data-testid="add-button"
                className="btn btn-add"
                onClick={() => setOpenAddClient(true)}
              >
                <img src={images.plus} alt="plus" aria-hidden="true" />
                <p>{t("index.upload_client")}</p>
              </button>
            )}
          </div>
        </div>
        {clients && clients?.length > 0 && !isLoading ? (
          <div className="client-index-container__table">
            <ClientTable clients={clients} />
          </div>
        ) : clients?.length === 0 && !isLoading ? (
          <div className="clients-empty-list">
            <div className="clients-empty-list__container">
              <img src={carImages.emptyCar} aria-hidden="true" />
              <div className="clients-empty-list__information">
                <h4 className="clients-empty-list__title">
                  {t("index.empty_title")}
                </h4>
                <p className="clients-empty-list__subtitle">
                  {t("index.empty_subtitle")}
                </p>
              </div>
              <button
                onClick={() => setOpenAddClient(true)}
                data-testid="add-button"
                className="btn btn-add"
              >
                <img src={images.plus} alt="plus" aria-hidden="true" />
                <p>{t("index.upload_client")}</p>
              </button>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </article>
      <AddClient setOpen={setOpenAddClient} open={openAddClient} />
    </>
  );
};
