import "./ClientTable.scss";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Client } from "../../../../models/client/types";
import { Chip } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import {
  DocStatus,
  TrustedUser,
  UserAccountStatus,
  UserStatus,
} from "../../../../models/user/types";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { KycStatus } from "../../../../views/profile/types";
import ClientMoreOptions from "../../../../components/clientMoreOptions/ClientMoreOptions";
import { useState } from "react";

const headCells: GridColDef[] = [
  {
    field: "name",
    headerName: "Nombre",
    width: 150,
    sortable: true,
  },
  {
    field: "surName",
    headerName: "Apellido",
    width: 150,
    sortable: true,
  },
  {
    field: "email",
    headerName: "Correo",
    width: 200,
    sortable: true,
  },
  {
    field: "phone",
    headerName: "Telefono",
    width: 150,
    sortable: true,
  },
  {
    field: "totalTrips",
    headerName: "Número de reservas",
    width: 200,
    sortable: true,
  },
  {
    field: "lastTripDate",
    headerName: "Última reserva",
    width: 200,
    sortable: true,
  },
  {
    field: "docOfficialDocuments",
    headerName: "Identificación oficial",
    width: 200,
    sortable: true,
    renderCell: (params) => {
      switch (params.value) {
        case KycStatus.PENDING:
        case KycStatus.SUCCESS:
          return (
            <Chip
              icon={<CheckCircleOutlineIcon color="success" />}
              label={"Completado"}
              sx={{
                backgroundColor: "#E8F5E9",
                color: "#2E7D32",
                padding: "4px 16px",
              }}
            />
          );
        case KycStatus.NOT_STARTED:
          return (
            <Chip
              icon={<WarningAmberIcon color="warning" />}
              label={"Incompleto"}
              sx={{
                backgroundColor: "#FFF8E1",
                color: "#F9A825",
                padding: "4px 16px",
              }}
            />
          );
        case KycStatus.FAIL:
          return (
            <Chip
              icon={<CloseIcon color="error" />}
              label={"Rechazada"}
              sx={{
                backgroundColor: "#FFEBEE",
                color: "#C62828",
                padding: "4px 16px",
              }}
            />
          );
      }
    },
  },
  {
    field: "docBankStatement",
    headerName: "Licencia de conductor",
    width: 200,
    sortable: true,
    renderCell: (params) => {
      switch (params.value) {
        case DocStatus.PENDING:
        case DocStatus.VALIDATED:
          return (
            <Chip
              icon={<CheckCircleOutlineIcon color="success" />}
              label={"Completado"}
              sx={{
                backgroundColor: "#E8F5E9",
                color: "#2E7D32",
                padding: "4px 16px",
              }}
            />
          );
        case DocStatus.NOT_UPLOADED:
          return (
            <Chip
              icon={<WarningAmberIcon color="warning" />}
              label={"Incompleto"}
              sx={{
                backgroundColor: "#FFF8E1",
                color: "#F9A825",
                padding: "4px 16px",
              }}
            />
          );
        case DocStatus.REFUSED:
          return (
            <Chip
              icon={<CloseIcon color="error" />}
              label={"Rechazada"}
              sx={{
                backgroundColor: "#FFEBEE",
                color: "#C62828",
                padding: "4px 16px",
              }}
            />
          );
      }
    },
  },
  {
    field: "docProofOfAddress",
    headerName: "Domicilio",
    width: 200,
    sortable: true,
    renderCell: (params) => {
      switch (params.value) {
        case DocStatus.PENDING:
        case DocStatus.VALIDATED:
          return (
            <Chip
              icon={<CheckCircleOutlineIcon color="success" />}
              label={"Completado"}
              sx={{
                backgroundColor: "#E8F5E9",
                color: "#2E7D32",
                padding: "4px 16px",
              }}
            />
          );
        case DocStatus.NOT_UPLOADED:
          return (
            <Chip
              icon={<WarningAmberIcon color="warning" />}
              label={"Incompleto"}
              sx={{
                backgroundColor: "#FFF8E1",
                color: "#F9A825",
                padding: "4px 16px",
              }}
            />
          );
        case DocStatus.REFUSED:
          return (
            <Chip
              icon={<CloseIcon color="error" />}
              label={"Rechazada"}
              sx={{
                backgroundColor: "#FFEBEE",
                color: "#C62828",
                padding: "4px 16px",
              }}
            />
          );
      }
    },
  },
  {
    field: "isBlocked",
    headerName: "Status",
    width: 200,
    renderCell: (params) => {
      if (params.value === UserStatus.VALID) {
        return (
          <Chip
            icon={<CheckCircleOutlineIcon color="success" />}
            label={"Autorizado"}
            sx={{
              backgroundColor: "#E8F5E9",
              color: "#2E7D32",
              padding: "4px 16px",
            }}
          />
        );
      } else {
        return (
          <Chip
            icon={<CancelOutlinedIcon color="error" />}
            label={"Bloqueado"}
            sx={{
              backgroundColor: "#FFEBEE",
              color: "#C62828",
              padding: "4px 16px",
            }}
          />
        );
      }
    },
  },
  {
    field: "status",
    headerName: "Registro",
    width: 200,
    sortable: true,
    renderCell: (params) => {
      if (params.value === UserAccountStatus.INVITED) {
        return (
          <Chip
            icon={<MarkEmailReadIcon color="primary" />}
            label={"Invitado"}
            sx={{
              backgroundColor: "#D2E2F5",
              color: "#4885CB",
              padding: "4px 16px",
            }}
          />
        );
      } else {
        return (
          <Chip
            icon={<CheckCircleOutlineIcon color="success" />}
            label={"Completado"}
            sx={{
              backgroundColor: "#E8F5E9",
              color: "#2E7D32",
              padding: "4px 16px",
            }}
          />
        );
      }
    },
  },
  {
    field: "trustedUser",
    headerName: "Confiado",
    width: 200,
    sortable: true,
    renderCell: (params) => {
      if (params.value === TrustedUser.FALSE) {
        return (
          <Chip
            icon={<CheckIcon color="action" />}
            label={"Regular"}
            sx={{
              backgroundColor: "#E9E9E9",
              color: "#4A4A57",
              padding: "4px 16px",
            }}
          />
        );
      } else {
        return (
          <Chip
            icon={<CheckCircleOutlineIcon color="success" />}
            label={"Confiado"}
            sx={{
              backgroundColor: "#E8F5E9",
              color: "#2E7D32",
              padding: "4px 16px",
            }}
          />
        );
      }
    },
  },
  {
    field: "dots",
    headerName: "",
    width: 20,
    sortable: true,
    renderCell: (params) => {
      const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

      return (
        <>
          <button
            className="btn-options"
            data-testid="show-options-button"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreVertIcon />
          </button>

          <ClientMoreOptions
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            client={params.row}
          />
        </>
      );
    },
  },
];

export const ClientTable = ({ clients }: { clients: Client[] }) => {
  return (
    <DataGridPro
      columns={headCells}
      rows={clients}
      className="client-table"
      rowsPerPageOptions={[10, 25, 50, 100]}
      pagination
      pinnedColumns={{ left: ["name"], right: ["dots"] }}
    />
  );
};
