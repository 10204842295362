import { useState, Dispatch, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Popover, List, ListItem, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Toast from "../toast/component";
import { Client } from "../../models/client/types";
import { ClientOptionsModal } from "../modals/clientOptions/ClientOptions";
import { UserAccountStatus, UserStatus } from "../../models/user/types";
import { AddClient } from "../modals/addClient/AddClient";
import { useQueryClient } from "react-query";
import {
  editClientByHost,
  resendInvitationClientByHost,
  resetPasswordClientByHost,
} from "../../services/client";
import { AlertContext } from "../../providers/alertProvider";

const ClientMoreOptions = ({
  anchorEl,
  setAnchorEl,
  client,
}: {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  client: Client;
}) => {
  const { setOpenAlert, setAlertMessage } = useContext(AlertContext);
  const queryClient = useQueryClient();
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditClient, setOpenEditClient] = useState(false);
  const { t } = useTranslation(["client", "routes"]);

  const getAction = (action: string) => {
    switch (action) {
      case "block":
        return setOpenBlockModal(true);
      case "unblock":
        return unblockClient();
      case "resend":
        return resendInvitationToClient();
      case "reset":
        return resetPasswordToClient();
      case "delete":
        return setOpenDeleteModal(true);
      case "edit":
        return setOpenEditClient(true);
    }

    setAnchorEl(null);
  };

  const unblockClient = async () => {
    if (!client) return;
    const response = await editClientByHost({
      id: client.id,
      isBlocked: UserStatus.VALID,
    });

    if (!response || response.status != 200) return;

    setOpenAlert(true);
    setAlertMessage({
      message: "El cliente ha sido desbloqueado con éxito.",
      type: "success",
    });
    queryClient.resetQueries(["clients"]);
  };

  const resendInvitationToClient = async () => {
    if (!client) return;
    const response = await resendInvitationClientByHost(client.id);

    if (!response || response.status != 200) return;

    setOpenAlert(true);
    setAlertMessage({
      message: "Invitación enviada exitosamente.",
      type: "success",
    });
  };

  const resetPasswordToClient = async () => {
    if (!client) return;
    const response = await resetPasswordClientByHost(client.id);

    if (!response || response.status != 200) return;

    setOpenAlert(true);
    setAlertMessage({
      message: "Restablecer correo electrónico enviado con éxito",
      type: "success",
    });
  };

  return (
    <>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <List onClick={(e: any) => getAction(e.target.dataset.action)}>
          <ListItem sx={{ cursor: "pointer" }}>
            <Link
              to={""}
              style={{ color: "#D1CFCF", textDecoration: "none" }}
              data-testid="edit-button"
            >
              {t("index.client_options.watch_details")}
            </Link>
          </ListItem>

          <ListItem
            data-action="edit"
            sx={{ cursor: "pointer" }}
            data-testid="edit-user-option"
          >
            {t("index.client_options.edit_user")}
          </ListItem>

          {client.isBlocked === UserStatus.BLOCKED ? (
            <ListItem
              data-action="unblock"
              sx={{ cursor: "pointer" }}
              data-testid="unblock-user-option"
            >
              {t("index.client_options.unblock_user")}
            </ListItem>
          ) : (
            <ListItem
              data-action="block"
              sx={{ cursor: "pointer" }}
              data-testid="block-user-option"
            >
              {t("index.client_options.block_user")}
            </ListItem>
          )}

          {client.status === UserAccountStatus.INVITED && (
            <ListItem
              data-action="resend"
              sx={{ cursor: "pointer" }}
              data-testid="block-user-option"
            >
              {t("index.client_options.resend_invitation")}
            </ListItem>
          )}

          <ListItem
            data-action="reset"
            sx={{ cursor: "pointer" }}
            data-testid="block-user-option"
          >
            {t("index.client_options.reset_password")}
          </ListItem>

          <ListItem
            data-action="delete"
            sx={{ cursor: "pointer" }}
            data-testid="delete-user-option"
          >
            {t("index.client_options.delete_user")}
          </ListItem>
        </List>
      </Popover>

      <ClientOptionsModal
        id={client.id}
        open={openBlockModal}
        setOpen={setOpenBlockModal}
        type="block"
      />

      <ClientOptionsModal
        id={client.id}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        type="delete"
      />

      <AddClient
        setOpen={setOpenEditClient}
        open={openEditClient}
        client={client}
      />
    </>
  );
};

export default ClientMoreOptions;
