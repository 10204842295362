import { AddClientForm } from "../components/modals/addClient/form";
import { Client, EditClient } from "../models/client/types";
import { User } from "../models/user/types";
import { IndexClientSearchForm } from "../views/clients/index/form";
import { axiosService } from "./axios";
import { getToken } from "./localstorage";

export const getClientsByHost = async (
  IndexClientSearch: IndexClientSearchForm
): Promise<Client[] | undefined> => {
  try {
    const response = await axiosService.post<[Client]>(
      `/account/index/users`,
      IndexClientSearch,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addClientByHost = async (AddClient: AddClientForm) => {
  try {
    const response = await axiosService.post(
      `/account/index/user/register`,
      AddClient,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const editClientByHost = async (editClient: EditClient) => {
  try {
    const response = await axiosService.patch(
      `/account/index/user/update`,
      editClient,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const resendInvitationClientByHost = async (id: number) => {
  try {
    const response = await axiosService.get(
      `/account/index/user/resend/invitation/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};

export const resetPasswordClientByHost = async (id: number) => {
  try {
    const response = await axiosService.get(
      `/account/index/user/reset/password/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};
